.json-tree {
  display: flex;
  flex-direction: column;
}


.json-tree .rejt-minus-menu,
.json-tree .rejt-plus-menu {
  display: none;
}

.rejt-edit-form input {
  border: 1px solid black;
  padding: 5px 7px;
}

.rejt-value-node, .rejt-object-node {
  text-align: left;
}
