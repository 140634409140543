.react-pdf__Document {
  padding: 20px;
}

.react-pdf__Page {
  margin-bottom: 20px;
}

.react-pdf__Page > .react-pdf__Page__canvas {
  border: 1px solid #ccc;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
}

.aggrForm {
  position: relative;
}

.aggrForm  .pdfForm > *{
  position: absolute;
  left: 62px;
}

.aggrForm .pdfForm > :first-child {
  top: -1050px;
}

.aggrForm .pdfForm > :nth-child(2),
.aggrForm .pdfForm > :nth-child(3) {
  left: 65px;
}
.aggrForm .pdfForm > :nth-child(2) {
  top: -980px;
}

.aggrForm .pdfForm > :nth-child(3) {
  top: -940px;
}

.aggrForm .pdfForm > :last-child {
  left: 150px;
  top: -870px;
}