@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&family=Poppins:wght@400;500;600;700&family=Roboto:wght@400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.landing-wrapper {
  height: 100vh;
  width: 100%;
  overflow: auto;
}

.landing {
  height: 100%;
  width: 100%;
  max-width: 1440px;
  margin: auto;
  font-family: "Roboto", sans-serif;
  @apply text-primary-300;
  text-align: initial;
}