@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
}

html,
body,
.App {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.5;
  letter-spacing: 0.015em;
  height: 100%;
  /* WKF-1214 If uncomment, it will cause incorrect rendering of PDF content, when a table is present */
  /* overflow: hidden; */
}

.App {
  overflow: hidden;
}

#app {
  overflow: auto;
  height: 100%;
}
